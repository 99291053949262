/**
 * Main App
 */
import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

// css
import './lib/embryoCss.js';
import './index.css';

// App locale
import AppLocale from './lang';

//layout
import HeaderTwo from './components/layouts/headers/HeaderTwo';
import FooterOne from './components/layouts/footers/FooterOne';

//review component
import ProductReview from './components/global/review-popup/ProductReview';

//Add Loaders
import {
  AsyncHomePageTwoComponent,
  AsyncAboutUSComponent,
  AsyncCartComponent,
  AsyncCheckOutComponent,
  AsyncCheckOutConfirmationComponent,
  AsyncBlogDetailComponent,
  AsyncAccessoriesComponent,
  AsyncContactUsComponent,
  AsyncFAQComponent,
  AsyncPaymentOptionsComponent,
  AsyncPrivacyPolicyComponent,
  AsyncTermAndConditionComponent,
  AsyncProductDetailComponent,
  AsyncInvoiceComponent,
  AsyncShopComponent,
  AsyncSignInPageComponent,
  AsyncSignUpComponent,
  AsyncForgetPasswordComponent,
  AsyncThankYouComponent,
  AsyncUserAccountComponent,
  AsyncPageNotFoundComponent,
} from './util/AsyncRoutes';

// actions
import { hideAlert } from './actions/action';

// footer data
import footerData from './assets/data/footerData';
import AdminLayout from './components/AdminLayout';
import theme from './themes/primaryTheme.js';

//const EcommerceLayout = () =>

class App extends React.Component {
  componentDidMount() {
    const { darkMode, rtlLayout } = this.props;
    this.rtlLayoutOption(rtlLayout);
    this.darkModeHanlder(darkMode);
  }

  rtlLayoutOption(rtlLayout) {
    if (rtlLayout === true) {
      document.body.classList.add('rtl-layout');
    } else {
      document.body.classList.remove('rtl-layout');
    }
  }

  darkModeHanlder(darkMode) {
    if (darkMode === true) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }
  /**
   * method for update window top when new page render
   */
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/admin-panel' ? true : false;
  }

  render() {
    const { location } = this.props;
    const { selectedLocale, alertType } = this.props;
    const currentAppLocale = AppLocale[selectedLocale.locale];

    return (
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <Fragment>
              {this.getUrl(location.pathname) ? (
                <Route path="/admin-panel" component={AdminLayout} />
              ) : (
                <div className="app-container">
                  <HeaderTwo />
                  {/* <ProductReview /> */}
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={AsyncHomePageTwoComponent}
                    />
                    <Route
                      path="/products/:type/:id"
                      component={AsyncProductDetailComponent}
                    />
                    <Route path="/cart" component={AsyncCartComponent} />
                    <Route
                      path="/check-out/confirmation"
                      component={AsyncCheckOutConfirmationComponent}
                    />
                    <Route
                      path="/check-out"
                      component={AsyncCheckOutComponent}
                    />
                    <Route
                      path="/payment"
                      component={AsyncPaymentOptionsComponent}
                    />
                    <Route
                      path="/final-receipt"
                      component={AsyncInvoiceComponent}
                    />

                    <Route path="/shop" component={AsyncShopComponent} />
                    {/* <Route path="/about-us" component={AsyncAboutUSComponent} /> */}
                    {/* <Route
                    path="/term-and-condition"
                    component={AsyncTermAndConditionComponent}
                  /> */}
                    {/* <Route
                    path="/privacy-policy"
                    component={AsyncPrivacyPolicyComponent}
                  /> */}
                    {/* <Route path="/faq" component={AsyncFAQComponent} /> */}
                    {/* <Route
                    path="/blogs/detail/:id"
                    component={AsyncBlogDetailComponent}
                  /> */}
                    <Route
                      path="/sign-in"
                      component={AsyncSignInPageComponent}
                    />
                    <Route path="/sign-up" component={AsyncSignUpComponent} />
                    {/* <Route
                    path="/forget-password"
                    component={AsyncForgetPasswordComponent}
                  /> */}
                    {/* <Route path="/thank-you" component={AsyncThankYouComponent} /> */}
                    <Route
                      path="/contact-us"
                      component={AsyncContactUsComponent}
                    />
                    <Route
                      path="/account"
                      component={AsyncUserAccountComponent}
                    />
                    <Route path="*" component={AsyncPageNotFoundComponent} />
                  </Switch>
                  <FooterOne data={footerData} />
                </div>
              )}
              <SweetAlert
                success={alertType === 'success'}
                error={alertType === 'error'}
                title=""
                confirmBtnText="Ok"
                confirmBtnBsStyle="warning"
                className="iron-alert-box"
                show={this.props.showAlert}
                onConfirm={this.props.hideAlert}
                onCancel={this.props.hideAlert}
                closeOnClickOutside
              >
                {this.props.alertMessage}
              </SweetAlert>
            </Fragment>
          </IntlProvider>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
  const {
    showAlert,
    alertMessage,
    selectedLocale,
    alertType,
    darkMode,
    rtlLayout,
  } = appSettings;
  return {
    showAlert,
    alertMessage,
    selectedLocale,
    alertType,
    darkMode,
    rtlLayout,
  };
};

export default connect(mapStateToProps, {
  hideAlert,
})(App);
