module.exports = {
  'menu.adminPanel': "panneau d'administration",
  'menu.Home': 'Accueil',
  'menu.HomeOne': 'Première maison',
  'menu.HomeTwo': 'Maison deux',
  'menu.HomeThree': 'Maison trois',
  'menu.fashion': 'mode',
  'menu.shop': 'boutique',
  'menu.gadgets': 'gadgets',
  'menu.accessories': 'accessoires',
  'menu.categories': 'catégories',
  'menu.pages': 'des pages',
  'menu.session': 'session',
  'menu.contact us': ' Contactez nous',
  'menu.vouchers': 'Chèques',
  'menu.men': 'Hommes',
  'menu.women': 'femmes',
  'menu.t-shirts': 't-shirts',
  'menu.jeans': 'jeans',
  'menu.shoes': 'chaussures',
  'menu.wallet': 'portefeuille',
  'menu.westernWear': 'usure occidentale',
  'menu.ethnicWear': 'tenue éthnique',
  'menu.sportsWear': 'tenue de sport',
  'menu.headPhones': 'headPhones',
  'menu.laptop': 'portable',
  'menu.speaker': 'orateur',
  'menu.watch': 'regarder',
  'menu.jewellery': 'bijoux',
  'menu.belts': 'ceintures',
  'menu.handBag': 'Sac à main',
  'menu.productDetail': 'détail du produit',
  'menu.cart': 'Chariot',
  'menu.checkout': 'check-out',
  'menu.payment': 'Paiement',
  'menu.blogDetail': 'détail du blog',
  'menu.userProfile': "profil de l'utilisateur",
  'menu.faq': 'FAQ',
  'menu.404Page': '404 page',
  'menu.signIn': 'se connecter',
  'menu.register': 'registre',
  'menu.forgotPassword': 'mot de passe oublié',
  'menu.thankYou': 'Je vous remercie',
  'menu.aboutUs': 'à propos de nous',
  'menu.termsAndConditions': 'Termes et conditions',
  'menu.privacyPolicy': 'politique de confidentialité',
  'footer.aboutUs': 'sur',
  'footer.termsAndCondition': 'termes et conditions',
  'footer.PrivacyPolicy': 'Politique de confidentialité',
  'footer.Faq': 'FAQ',
  'footer.contactUs': 'Contactez nous',
  'footer.signIn': 'se connecter',
  'footer.register': 'registre',
  'footer.forgetPassword': 'mot de passe oublié',
  'footer.thankYou': 'Je vous remercie',
  'footer.women': 'femmes',
  'footer.men': 'Hommes',
  'footer.accessories': 'accessoires',
  'footer.gadgets': 'gadgets',
  'footer.facebook': 'facebook',
  'footer.twitter': 'gazouillement',
  'footer.youtube': 'youtube',
  'footer.googlePlus': 'google plus',
  'admin.reports': 'rapports',
  'admin.invoices': 'factures',
  'admin.products': 'des produits',
  'admin.profile': 'profil',
  'admin.productAdd': 'ajout de produit',
  'admin.goToHome': 'aller à la maison',
  'admin.vouchers': 'Chèques',
  'admin.tiers': 'paliers',
};

